.owl-item {
  filter: grayscale(1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.item-content {
  text-align: center;
  font-weight: 400;
  height: inherit;
}
.owl-carousel .owl-stage {
  display: flex;
}

